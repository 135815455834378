// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file proto/card/card.proto (package card, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * Card related messages and services
 *
 * @generated from message card.Card
 */
export class Card extends Message<Card> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string biome = 3;
   */
  biome = "";

  /**
   * @generated from field: string element = 4;
   */
  element = "";

  /**
   * @generated from field: string animal = 5;
   */
  animal = "";

  /**
   * @generated from field: string plant = 6;
   */
  plant = "";

  /**
   * @generated from field: string description = 7;
   */
  description = "";

  /**
   * @generated from field: string img_url = 8;
   */
  imgUrl = "";

  /**
   * @generated from field: string player = 9;
   */
  player = "";

  /**
   * @generated from field: string type = 10;
   */
  type = "";

  /**
   * @generated from field: string attack = 11;
   */
  attack = "";

  /**
   * @generated from field: string defense = 12;
   */
  defense = "";

  /**
   * @generated from field: string action = 13;
   */
  action = "";

  /**
   * @generated from field: string flavor = 14;
   */
  flavor = "";

  /**
   * @generated from field: string mod = 15;
   */
  mod = "";

  /**
   * @generated from field: string deck = 16;
   */
  deck = "";

  constructor(data?: PartialMessage<Card>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.Card";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "biome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "element", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "animal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "plant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "player", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "attack", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "defense", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "flavor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "mod", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "deck", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Card {
    return new Card().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Card {
    return new Card().fromJsonString(jsonString, options);
  }

  static equals(a: Card | PlainMessage<Card> | undefined, b: Card | PlainMessage<Card> | undefined): boolean {
    return proto3.util.equals(Card, a, b);
  }
}

/**
 * @generated from message card.GetCardRequest
 */
export class GetCardRequest extends Message<GetCardRequest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  constructor(data?: PartialMessage<GetCardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetCardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCardRequest {
    return new GetCardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCardRequest {
    return new GetCardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCardRequest {
    return new GetCardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCardRequest | PlainMessage<GetCardRequest> | undefined, b: GetCardRequest | PlainMessage<GetCardRequest> | undefined): boolean {
    return proto3.util.equals(GetCardRequest, a, b);
  }
}

/**
 * @generated from message card.GetCardResponse
 */
export class GetCardResponse extends Message<GetCardResponse> {
  /**
   * @generated from field: card.Card card = 1;
   */
  card?: Card;

  constructor(data?: PartialMessage<GetCardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetCardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card", kind: "message", T: Card },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCardResponse {
    return new GetCardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCardResponse {
    return new GetCardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCardResponse {
    return new GetCardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCardResponse | PlainMessage<GetCardResponse> | undefined, b: GetCardResponse | PlainMessage<GetCardResponse> | undefined): boolean {
    return proto3.util.equals(GetCardResponse, a, b);
  }
}

/**
 * @generated from message card.CardTemplate
 */
export class CardTemplate extends Message<CardTemplate> {
  /**
   * @generated from field: string template = 1;
   */
  template = "";

  /**
   * @generated from field: repeated string animals = 2;
   */
  animals: string[] = [];

  /**
   * @generated from field: repeated string plants = 3;
   */
  plants: string[] = [];

  /**
   * @generated from field: repeated string elements = 4;
   */
  elements: string[] = [];

  /**
   * @generated from field: repeated string descriptions = 5;
   */
  descriptions: string[] = [];

  /**
   * @generated from field: repeated string resources = 6;
   */
  resources: string[] = [];

  constructor(data?: PartialMessage<CardTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CardTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "animals", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "plants", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "elements", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "descriptions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardTemplate {
    return new CardTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardTemplate {
    return new CardTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardTemplate {
    return new CardTemplate().fromJsonString(jsonString, options);
  }

  static equals(a: CardTemplate | PlainMessage<CardTemplate> | undefined, b: CardTemplate | PlainMessage<CardTemplate> | undefined): boolean {
    return proto3.util.equals(CardTemplate, a, b);
  }
}

/**
 * @generated from message card.GenerateCardsRequest
 */
export class GenerateCardsRequest extends Message<GenerateCardsRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string biome = 2;
   */
  biome = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * @generated from field: string deck = 4;
   */
  deck = "";

  constructor(data?: PartialMessage<GenerateCardsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GenerateCardsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "biome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "deck", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCardsRequest {
    return new GenerateCardsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCardsRequest {
    return new GenerateCardsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCardsRequest {
    return new GenerateCardsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCardsRequest | PlainMessage<GenerateCardsRequest> | undefined, b: GenerateCardsRequest | PlainMessage<GenerateCardsRequest> | undefined): boolean {
    return proto3.util.equals(GenerateCardsRequest, a, b);
  }
}

/**
 * @generated from message card.GenerateCardsResponse
 */
export class GenerateCardsResponse extends Message<GenerateCardsResponse> {
  /**
   * @generated from field: repeated card.Card cards = 1;
   */
  cards: Card[] = [];

  constructor(data?: PartialMessage<GenerateCardsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GenerateCardsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cards", kind: "message", T: Card, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCardsResponse {
    return new GenerateCardsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCardsResponse {
    return new GenerateCardsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCardsResponse {
    return new GenerateCardsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCardsResponse | PlainMessage<GenerateCardsResponse> | undefined, b: GenerateCardsResponse | PlainMessage<GenerateCardsResponse> | undefined): boolean {
    return proto3.util.equals(GenerateCardsResponse, a, b);
  }
}

/**
 * @generated from message card.DeleteCardRequest
 */
export class DeleteCardRequest extends Message<DeleteCardRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: int32 card_id = 2;
   */
  cardId = 0;

  constructor(data?: PartialMessage<DeleteCardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.DeleteCardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "card_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCardRequest {
    return new DeleteCardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCardRequest {
    return new DeleteCardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCardRequest {
    return new DeleteCardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCardRequest | PlainMessage<DeleteCardRequest> | undefined, b: DeleteCardRequest | PlainMessage<DeleteCardRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCardRequest, a, b);
  }
}

/**
 * @generated from message card.DeleteCardResponse
 */
export class DeleteCardResponse extends Message<DeleteCardResponse> {
  /**
   * @generated from field: int32 card_id = 1;
   */
  cardId = 0;

  constructor(data?: PartialMessage<DeleteCardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.DeleteCardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCardResponse {
    return new DeleteCardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCardResponse {
    return new DeleteCardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCardResponse {
    return new DeleteCardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCardResponse | PlainMessage<DeleteCardResponse> | undefined, b: DeleteCardResponse | PlainMessage<DeleteCardResponse> | undefined): boolean {
    return proto3.util.equals(DeleteCardResponse, a, b);
  }
}

/**
 * @generated from message card.GetCardsRequest
 */
export class GetCardsRequest extends Message<GetCardsRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<GetCardsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetCardsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCardsRequest {
    return new GetCardsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCardsRequest {
    return new GetCardsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCardsRequest {
    return new GetCardsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCardsRequest | PlainMessage<GetCardsRequest> | undefined, b: GetCardsRequest | PlainMessage<GetCardsRequest> | undefined): boolean {
    return proto3.util.equals(GetCardsRequest, a, b);
  }
}

/**
 * @generated from message card.GetCardsResponse
 */
export class GetCardsResponse extends Message<GetCardsResponse> {
  /**
   * @generated from field: repeated card.Card cards = 1;
   */
  cards: Card[] = [];

  constructor(data?: PartialMessage<GetCardsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetCardsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cards", kind: "message", T: Card, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCardsResponse {
    return new GetCardsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCardsResponse {
    return new GetCardsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCardsResponse {
    return new GetCardsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCardsResponse | PlainMessage<GetCardsResponse> | undefined, b: GetCardsResponse | PlainMessage<GetCardsResponse> | undefined): boolean {
    return proto3.util.equals(GetCardsResponse, a, b);
  }
}

/**
 * @generated from message card.NewCardRequest
 */
export class NewCardRequest extends Message<NewCardRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: card.Card card = 2;
   */
  card?: Card;

  constructor(data?: PartialMessage<NewCardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.NewCardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "card", kind: "message", T: Card },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewCardRequest {
    return new NewCardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewCardRequest {
    return new NewCardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewCardRequest {
    return new NewCardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NewCardRequest | PlainMessage<NewCardRequest> | undefined, b: NewCardRequest | PlainMessage<NewCardRequest> | undefined): boolean {
    return proto3.util.equals(NewCardRequest, a, b);
  }
}

/**
 * @generated from message card.NewCardResponse
 */
export class NewCardResponse extends Message<NewCardResponse> {
  /**
   * @generated from field: card.Card card = 1;
   */
  card?: Card;

  constructor(data?: PartialMessage<NewCardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.NewCardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card", kind: "message", T: Card },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewCardResponse {
    return new NewCardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewCardResponse {
    return new NewCardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewCardResponse {
    return new NewCardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NewCardResponse | PlainMessage<NewCardResponse> | undefined, b: NewCardResponse | PlainMessage<NewCardResponse> | undefined): boolean {
    return proto3.util.equals(NewCardResponse, a, b);
  }
}

/**
 * @generated from message card.CreateCardRequest
 */
export class CreateCardRequest extends Message<CreateCardRequest> {
  /**
   * @generated from field: card.Card card = 1;
   */
  card?: Card;

  /**
   * @generated from field: string prompt = 2;
   */
  prompt = "";

  constructor(data?: PartialMessage<CreateCardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateCardRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card", kind: "message", T: Card },
    { no: 2, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCardRequest {
    return new CreateCardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCardRequest {
    return new CreateCardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCardRequest {
    return new CreateCardRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCardRequest | PlainMessage<CreateCardRequest> | undefined, b: CreateCardRequest | PlainMessage<CreateCardRequest> | undefined): boolean {
    return proto3.util.equals(CreateCardRequest, a, b);
  }
}

/**
 * @generated from message card.CreateCardResponse
 */
export class CreateCardResponse extends Message<CreateCardResponse> {
  /**
   * @generated from field: card.Card card = 1;
   */
  card?: Card;

  constructor(data?: PartialMessage<CreateCardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateCardResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card", kind: "message", T: Card },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCardResponse {
    return new CreateCardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCardResponse {
    return new CreateCardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCardResponse {
    return new CreateCardResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCardResponse | PlainMessage<CreateCardResponse> | undefined, b: CreateCardResponse | PlainMessage<CreateCardResponse> | undefined): boolean {
    return proto3.util.equals(CreateCardResponse, a, b);
  }
}

/**
 * @generated from message card.CreateCardTemplateRequest
 */
export class CreateCardTemplateRequest extends Message<CreateCardTemplateRequest> {
  /**
   * @generated from field: card.CardTemplate card_template = 1;
   */
  cardTemplate?: CardTemplate;

  /**
   * @generated from field: string biome = 2;
   */
  biome = "";

  constructor(data?: PartialMessage<CreateCardTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateCardTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_template", kind: "message", T: CardTemplate },
    { no: 2, name: "biome", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCardTemplateRequest {
    return new CreateCardTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCardTemplateRequest {
    return new CreateCardTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCardTemplateRequest {
    return new CreateCardTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCardTemplateRequest | PlainMessage<CreateCardTemplateRequest> | undefined, b: CreateCardTemplateRequest | PlainMessage<CreateCardTemplateRequest> | undefined): boolean {
    return proto3.util.equals(CreateCardTemplateRequest, a, b);
  }
}

/**
 * @generated from message card.CreateCardTemplateResponse
 */
export class CreateCardTemplateResponse extends Message<CreateCardTemplateResponse> {
  /**
   * @generated from field: card.CardTemplate card_template = 1;
   */
  cardTemplate?: CardTemplate;

  constructor(data?: PartialMessage<CreateCardTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateCardTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_template", kind: "message", T: CardTemplate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCardTemplateResponse {
    return new CreateCardTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCardTemplateResponse {
    return new CreateCardTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCardTemplateResponse {
    return new CreateCardTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCardTemplateResponse | PlainMessage<CreateCardTemplateResponse> | undefined, b: CreateCardTemplateResponse | PlainMessage<CreateCardTemplateResponse> | undefined): boolean {
    return proto3.util.equals(CreateCardTemplateResponse, a, b);
  }
}

/**
 * @generated from message card.Deck
 */
export class Deck extends Message<Deck> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated card.Card cards = 2;
   */
  cards: Card[] = [];

  constructor(data?: PartialMessage<Deck>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.Deck";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cards", kind: "message", T: Card, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Deck {
    return new Deck().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Deck {
    return new Deck().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Deck {
    return new Deck().fromJsonString(jsonString, options);
  }

  static equals(a: Deck | PlainMessage<Deck> | undefined, b: Deck | PlainMessage<Deck> | undefined): boolean {
    return proto3.util.equals(Deck, a, b);
  }
}

/**
 * @generated from message card.GetDecksResponse
 */
export class GetDecksResponse extends Message<GetDecksResponse> {
  /**
   * @generated from field: repeated card.Deck decks = 1;
   */
  decks: Deck[] = [];

  constructor(data?: PartialMessage<GetDecksResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetDecksResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "decks", kind: "message", T: Deck, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDecksResponse {
    return new GetDecksResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDecksResponse {
    return new GetDecksResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDecksResponse {
    return new GetDecksResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDecksResponse | PlainMessage<GetDecksResponse> | undefined, b: GetDecksResponse | PlainMessage<GetDecksResponse> | undefined): boolean {
    return proto3.util.equals(GetDecksResponse, a, b);
  }
}

/**
 * @generated from message card.GetDeckRequest
 */
export class GetDeckRequest extends Message<GetDeckRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetDeckRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.GetDeckRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDeckRequest {
    return new GetDeckRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDeckRequest {
    return new GetDeckRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDeckRequest {
    return new GetDeckRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDeckRequest | PlainMessage<GetDeckRequest> | undefined, b: GetDeckRequest | PlainMessage<GetDeckRequest> | undefined): boolean {
    return proto3.util.equals(GetDeckRequest, a, b);
  }
}

/**
 * @generated from message card.CreateDeckTemplateRequest
 */
export class CreateDeckTemplateRequest extends Message<CreateDeckTemplateRequest> {
  /**
   * @generated from field: string settting = 1;
   */
  settting = "";

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  /**
   * @generated from field: string deck = 4;
   */
  deck = "";

  constructor(data?: PartialMessage<CreateDeckTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateDeckTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settting", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deck", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDeckTemplateRequest {
    return new CreateDeckTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDeckTemplateRequest {
    return new CreateDeckTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDeckTemplateRequest {
    return new CreateDeckTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDeckTemplateRequest | PlainMessage<CreateDeckTemplateRequest> | undefined, b: CreateDeckTemplateRequest | PlainMessage<CreateDeckTemplateRequest> | undefined): boolean {
    return proto3.util.equals(CreateDeckTemplateRequest, a, b);
  }
}

/**
 * @generated from message card.CreateDeckTemplateResponse
 */
export class CreateDeckTemplateResponse extends Message<CreateDeckTemplateResponse> {
  /**
   * @generated from field: string template = 1;
   */
  template = "";

  /**
   * @generated from field: card.Deck deck = 2;
   */
  deck?: Deck;

  constructor(data?: PartialMessage<CreateDeckTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "card.CreateDeckTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deck", kind: "message", T: Deck },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDeckTemplateResponse {
    return new CreateDeckTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDeckTemplateResponse {
    return new CreateDeckTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDeckTemplateResponse {
    return new CreateDeckTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDeckTemplateResponse | PlainMessage<CreateDeckTemplateResponse> | undefined, b: CreateDeckTemplateResponse | PlainMessage<CreateDeckTemplateResponse> | undefined): boolean {
    return proto3.util.equals(CreateDeckTemplateResponse, a, b);
  }
}

