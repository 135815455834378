// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file proto/blog/blog.proto (package blog, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AssociateTagWithPostRequest, AssociateTagWithPostResponse, CreatePostRequest, CreatePostResponse, CreateTagRequest, CreateTagResponse, DeletePostRequest, DeletePostResponse, GetPostRequest, GetPostResponse, GetPostsRequest, GetPostsResponse, GetTagsForPostRequest, GetTagsForPostResponse, GetTagsRequest, GetTagsResponse } from "./blog_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service blog.BlogService
 */
export const BlogService = {
  typeName: "blog.BlogService",
  methods: {
    /**
     * @generated from rpc blog.BlogService.CreatePost
     */
    createPost: {
      name: "CreatePost",
      I: CreatePostRequest,
      O: CreatePostResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.GetPosts
     */
    getPosts: {
      name: "GetPosts",
      I: GetPostsRequest,
      O: GetPostsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.CreateTag
     */
    createTag: {
      name: "CreateTag",
      I: CreateTagRequest,
      O: CreateTagResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.AssociateTagWithPost
     */
    associateTagWithPost: {
      name: "AssociateTagWithPost",
      I: AssociateTagWithPostRequest,
      O: AssociateTagWithPostResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.GetTagsForPost
     */
    getTagsForPost: {
      name: "GetTagsForPost",
      I: GetTagsForPostRequest,
      O: GetTagsForPostResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.GetTags
     */
    getTags: {
      name: "GetTags",
      I: GetTagsRequest,
      O: GetTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.GetPost
     */
    getPost: {
      name: "GetPost",
      I: GetPostRequest,
      O: GetPostResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc blog.BlogService.DeletePost
     */
    deletePost: {
      name: "DeletePost",
      I: DeletePostRequest,
      O: DeletePostResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

