// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file proto/blog/blog.proto (package blog, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message blog.Post
 */
export class Post extends Message<Post> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * @generated from field: string author = 4;
   */
  author = "";

  /**
   * @generated from field: repeated blog.Tag tags = 5;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<Post>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.Post";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "author", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Post {
    return new Post().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Post {
    return new Post().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Post {
    return new Post().fromJsonString(jsonString, options);
  }

  static equals(a: Post | PlainMessage<Post> | undefined, b: Post | PlainMessage<Post> | undefined): boolean {
    return proto3.util.equals(Post, a, b);
  }
}

/**
 * @generated from message blog.Posts
 */
export class Posts extends Message<Posts> {
  /**
   * @generated from field: repeated blog.Post posts = 1;
   */
  posts: Post[] = [];

  constructor(data?: PartialMessage<Posts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.Posts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: Post, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Posts {
    return new Posts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Posts {
    return new Posts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Posts {
    return new Posts().fromJsonString(jsonString, options);
  }

  static equals(a: Posts | PlainMessage<Posts> | undefined, b: Posts | PlainMessage<Posts> | undefined): boolean {
    return proto3.util.equals(Posts, a, b);
  }
}

/**
 * @generated from message blog.CreatePostRequest
 */
export class CreatePostRequest extends Message<CreatePostRequest> {
  /**
   * @generated from field: blog.Post post = 1;
   */
  post?: Post;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<CreatePostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.CreatePostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: Post },
    { no: 2, name: "tag_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostRequest {
    return new CreatePostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined, b: CreatePostRequest | PlainMessage<CreatePostRequest> | undefined): boolean {
    return proto3.util.equals(CreatePostRequest, a, b);
  }
}

/**
 * @generated from message blog.CreatePostResponse
 */
export class CreatePostResponse extends Message<CreatePostResponse> {
  /**
   * @generated from field: blog.Post post = 1;
   */
  post?: Post;

  constructor(data?: PartialMessage<CreatePostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.CreatePostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: Post },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePostResponse {
    return new CreatePostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined, b: CreatePostResponse | PlainMessage<CreatePostResponse> | undefined): boolean {
    return proto3.util.equals(CreatePostResponse, a, b);
  }
}

/**
 * @generated from message blog.GetPostsResponse
 */
export class GetPostsResponse extends Message<GetPostsResponse> {
  /**
   * @generated from field: blog.Posts posts = 1;
   */
  posts?: Posts;

  constructor(data?: PartialMessage<GetPostsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetPostsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: Posts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPostsResponse {
    return new GetPostsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPostsResponse {
    return new GetPostsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPostsResponse {
    return new GetPostsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPostsResponse | PlainMessage<GetPostsResponse> | undefined, b: GetPostsResponse | PlainMessage<GetPostsResponse> | undefined): boolean {
    return proto3.util.equals(GetPostsResponse, a, b);
  }
}

/**
 * @generated from message blog.GetPostsRequest
 */
export class GetPostsRequest extends Message<GetPostsRequest> {
  /**
   * @generated from field: blog.Posts posts = 1;
   */
  posts?: Posts;

  /**
   * @generated from field: repeated string tags = 2;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<GetPostsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetPostsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: Posts },
    { no: 2, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPostsRequest {
    return new GetPostsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPostsRequest {
    return new GetPostsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPostsRequest {
    return new GetPostsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPostsRequest | PlainMessage<GetPostsRequest> | undefined, b: GetPostsRequest | PlainMessage<GetPostsRequest> | undefined): boolean {
    return proto3.util.equals(GetPostsRequest, a, b);
  }
}

/**
 * @generated from message blog.GetPostRequest
 */
export class GetPostRequest extends Message<GetPostRequest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  constructor(data?: PartialMessage<GetPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPostRequest {
    return new GetPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPostRequest {
    return new GetPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPostRequest {
    return new GetPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPostRequest | PlainMessage<GetPostRequest> | undefined, b: GetPostRequest | PlainMessage<GetPostRequest> | undefined): boolean {
    return proto3.util.equals(GetPostRequest, a, b);
  }
}

/**
 * @generated from message blog.GetPostResponse
 */
export class GetPostResponse extends Message<GetPostResponse> {
  /**
   * @generated from field: blog.Post post = 1;
   */
  post?: Post;

  constructor(data?: PartialMessage<GetPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post", kind: "message", T: Post },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPostResponse {
    return new GetPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPostResponse {
    return new GetPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPostResponse {
    return new GetPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPostResponse | PlainMessage<GetPostResponse> | undefined, b: GetPostResponse | PlainMessage<GetPostResponse> | undefined): boolean {
    return proto3.util.equals(GetPostResponse, a, b);
  }
}

/**
 * @generated from message blog.DeletePostRequest
 */
export class DeletePostRequest extends Message<DeletePostRequest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  constructor(data?: PartialMessage<DeletePostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.DeletePostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePostRequest {
    return new DeletePostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePostRequest | PlainMessage<DeletePostRequest> | undefined, b: DeletePostRequest | PlainMessage<DeletePostRequest> | undefined): boolean {
    return proto3.util.equals(DeletePostRequest, a, b);
  }
}

/**
 * @generated from message blog.DeletePostResponse
 */
export class DeletePostResponse extends Message<DeletePostResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  constructor(data?: PartialMessage<DeletePostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.DeletePostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePostResponse {
    return new DeletePostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePostResponse | PlainMessage<DeletePostResponse> | undefined, b: DeletePostResponse | PlainMessage<DeletePostResponse> | undefined): boolean {
    return proto3.util.equals(DeletePostResponse, a, b);
  }
}

/**
 * @generated from message blog.Tag
 */
export class Tag extends Message<Tag> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Tag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.Tag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tag {
    return new Tag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJsonString(jsonString, options);
  }

  static equals(a: Tag | PlainMessage<Tag> | undefined, b: Tag | PlainMessage<Tag> | undefined): boolean {
    return proto3.util.equals(Tag, a, b);
  }
}

/**
 * @generated from message blog.CreateTagRequest
 */
export class CreateTagRequest extends Message<CreateTagRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<CreateTagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.CreateTagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTagRequest {
    return new CreateTagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTagRequest {
    return new CreateTagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTagRequest {
    return new CreateTagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTagRequest | PlainMessage<CreateTagRequest> | undefined, b: CreateTagRequest | PlainMessage<CreateTagRequest> | undefined): boolean {
    return proto3.util.equals(CreateTagRequest, a, b);
  }
}

/**
 * @generated from message blog.CreateTagResponse
 */
export class CreateTagResponse extends Message<CreateTagResponse> {
  /**
   * @generated from field: blog.Tag tag = 1;
   */
  tag?: Tag;

  constructor(data?: PartialMessage<CreateTagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.CreateTagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "message", T: Tag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTagResponse {
    return new CreateTagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTagResponse {
    return new CreateTagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTagResponse {
    return new CreateTagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTagResponse | PlainMessage<CreateTagResponse> | undefined, b: CreateTagResponse | PlainMessage<CreateTagResponse> | undefined): boolean {
    return proto3.util.equals(CreateTagResponse, a, b);
  }
}

/**
 * @generated from message blog.AssociateTagWithPostRequest
 */
export class AssociateTagWithPostRequest extends Message<AssociateTagWithPostRequest> {
  /**
   * @generated from field: int32 post_id = 1;
   */
  postId = 0;

  /**
   * @generated from field: int32 tag_id = 2;
   */
  tagId = 0;

  constructor(data?: PartialMessage<AssociateTagWithPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.AssociateTagWithPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssociateTagWithPostRequest {
    return new AssociateTagWithPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssociateTagWithPostRequest {
    return new AssociateTagWithPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssociateTagWithPostRequest {
    return new AssociateTagWithPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssociateTagWithPostRequest | PlainMessage<AssociateTagWithPostRequest> | undefined, b: AssociateTagWithPostRequest | PlainMessage<AssociateTagWithPostRequest> | undefined): boolean {
    return proto3.util.equals(AssociateTagWithPostRequest, a, b);
  }
}

/**
 * @generated from message blog.AssociateTagWithPostResponse
 */
export class AssociateTagWithPostResponse extends Message<AssociateTagWithPostResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  constructor(data?: PartialMessage<AssociateTagWithPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.AssociateTagWithPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssociateTagWithPostResponse {
    return new AssociateTagWithPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssociateTagWithPostResponse {
    return new AssociateTagWithPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssociateTagWithPostResponse {
    return new AssociateTagWithPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssociateTagWithPostResponse | PlainMessage<AssociateTagWithPostResponse> | undefined, b: AssociateTagWithPostResponse | PlainMessage<AssociateTagWithPostResponse> | undefined): boolean {
    return proto3.util.equals(AssociateTagWithPostResponse, a, b);
  }
}

/**
 * @generated from message blog.GetTagsRequest
 */
export class GetTagsRequest extends Message<GetTagsRequest> {
  constructor(data?: PartialMessage<GetTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined, b: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined): boolean {
    return proto3.util.equals(GetTagsRequest, a, b);
  }
}

/**
 * @generated from message blog.GetTagsResponse
 */
export class GetTagsResponse extends Message<GetTagsResponse> {
  /**
   * @generated from field: repeated blog.Tag tags = 1;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<GetTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetTagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined, b: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined): boolean {
    return proto3.util.equals(GetTagsResponse, a, b);
  }
}

/**
 * @generated from message blog.GetTagsForPostRequest
 */
export class GetTagsForPostRequest extends Message<GetTagsForPostRequest> {
  /**
   * @generated from field: int32 post_id = 1;
   */
  postId = 0;

  constructor(data?: PartialMessage<GetTagsForPostRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetTagsForPostRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsForPostRequest {
    return new GetTagsForPostRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsForPostRequest {
    return new GetTagsForPostRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsForPostRequest {
    return new GetTagsForPostRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsForPostRequest | PlainMessage<GetTagsForPostRequest> | undefined, b: GetTagsForPostRequest | PlainMessage<GetTagsForPostRequest> | undefined): boolean {
    return proto3.util.equals(GetTagsForPostRequest, a, b);
  }
}

/**
 * @generated from message blog.GetTagsForPostResponse
 */
export class GetTagsForPostResponse extends Message<GetTagsForPostResponse> {
  /**
   * @generated from field: repeated blog.Tag tags = 1;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<GetTagsForPostResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "blog.GetTagsForPostResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsForPostResponse {
    return new GetTagsForPostResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsForPostResponse {
    return new GetTagsForPostResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsForPostResponse {
    return new GetTagsForPostResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsForPostResponse | PlainMessage<GetTagsForPostResponse> | undefined, b: GetTagsForPostResponse | PlainMessage<GetTagsForPostResponse> | undefined): boolean {
    return proto3.util.equals(GetTagsForPostResponse, a, b);
  }
}

