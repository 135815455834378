// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file proto/card/card.proto (package card, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateCardRequest, CreateCardResponse, CreateCardTemplateRequest, CreateCardTemplateResponse, CreateDeckTemplateRequest, CreateDeckTemplateResponse, Deck, DeleteCardRequest, DeleteCardResponse, GenerateCardsRequest, GenerateCardsResponse, GetCardRequest, GetCardResponse, GetCardsRequest, GetCardsResponse, GetDeckRequest, GetDecksResponse, NewCardRequest, NewCardResponse } from "./card_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service card.CardService
 */
export const CardService = {
  typeName: "card.CardService",
  methods: {
    /**
     * @generated from rpc card.CardService.GetCards
     */
    getCards: {
      name: "GetCards",
      I: GetCardsRequest,
      O: GetCardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.NewCard
     */
    newCard: {
      name: "NewCard",
      I: NewCardRequest,
      O: NewCardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.DeleteCard
     */
    deleteCard: {
      name: "DeleteCard",
      I: DeleteCardRequest,
      O: DeleteCardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.GenerateCards
     */
    generateCards: {
      name: "GenerateCards",
      I: GenerateCardsRequest,
      O: GenerateCardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.CreateCardTemplate
     */
    createCardTemplate: {
      name: "CreateCardTemplate",
      I: CreateCardTemplateRequest,
      O: CreateCardTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.CreateCard
     */
    createCard: {
      name: "CreateCard",
      I: CreateCardRequest,
      O: CreateCardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.GetCard
     */
    getCard: {
      name: "GetCard",
      I: GetCardRequest,
      O: GetCardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.GetDecks
     */
    getDecks: {
      name: "GetDecks",
      I: GetCardsRequest,
      O: GetDecksResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.GetDeck
     */
    getDeck: {
      name: "GetDeck",
      I: GetDeckRequest,
      O: Deck,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc card.CardService.CreateDeckTemplate
     */
    createDeckTemplate: {
      name: "CreateDeckTemplate",
      I: CreateDeckTemplateRequest,
      O: CreateDeckTemplateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

