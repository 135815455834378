// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file proto/biome/biome.proto (package biome, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Biome, CardResponse, CreateBiomeRequest, CreateBiomeResponse, GetBiomeRequest, GetBiomeResponse, GetBiomesRequest, GetBiomesResponse } from "./biome_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Service definition
 *
 * @generated from service biome.BiomeService
 */
export const BiomeService = {
  typeName: "biome.BiomeService",
  methods: {
    /**
     * @generated from rpc biome.BiomeService.CreateBiome
     */
    createBiome: {
      name: "CreateBiome",
      I: CreateBiomeRequest,
      O: CreateBiomeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc biome.BiomeService.GetBiome
     */
    getBiome: {
      name: "GetBiome",
      I: GetBiomeRequest,
      O: GetBiomeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc biome.BiomeService.GetBiomes
     */
    getBiomes: {
      name: "GetBiomes",
      I: GetBiomesRequest,
      O: GetBiomesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc biome.BiomeService.GenerateBiomeCard
     */
    generateBiomeCard: {
      name: "GenerateBiomeCard",
      I: Biome,
      O: CardResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

